$(document).ready(function() {
    // Activate deletion confirmation
    $('.delete-confirm').on('submit', function() {
        return confirm('Are you sure you want to delete this?');
    });

    $('.two-fifty').textcounter({
        type: 'word',
        max: 250,
        counterText: 'Total Words: '
    });

    $('.datepicker').datepicker({
        autohide: true
    });

    $('.wysiwyg').trumbowyg({
        removeformatPasted: true,
        autogrow: true,
        btns: [
          ['viewHTML'],
          ['formatting'],
          'btnGrp-semantic',
          ['superscript', 'subscript'],
          ['link'],
          'btnGrp-justify',
          'btnGrp-lists',
          ['removeformat']
        ]
    });
});


$(document).ready(function() {
  var menuToggle = $('#js-centered-navigation-mobile-menu').unbind();
  $('#js-centered-navigation-menu').removeClass("show");

  menuToggle.on('click', function(e) {
    e.preventDefault();
    $('#js-centered-navigation-menu').slideToggle(function(){
      if($('#js-centered-navigation-menu').is(':hidden')) {
        $('#js-centered-navigation-menu').removeAttr('style');
      }
    });
  });
});


jQuery(".responsive_headline").fitText(0.5, { minFontSize: '40px', maxFontSize: '80px' });
jQuery(".responsive_quote").fitText(1, { minFontSize: '15px', maxFontSize: '24px' });

function openStat(evt, cityName) {
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("stats-container");
    for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(" active", "");
        tablinks[i].style.backgroundColor = "#D71920";
    }
    document.getElementById(cityName).style.display = "block";
    evt.currentTarget.className += " active";
    evt.currentTarget.style.backgroundColor = "#9c181d";
}


/*
 * For the outcomes page, do a little JS work.
 */
$(document).ready(function(){
    // Activate the slider
    $('.single-item').slick();
    // Click the tab
    if (document.getElementById("0_button")) {
        document.getElementById("0_button").click();
    }

});
